import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Button, Checkbox} from "@material-ui/core"

const SecondPage = () => (
    <Layout>

        <div>
            <Button variant="contained">Default</Button>
            <Button variant="contained" color="primary">
                Primary
            </Button>
            <Button variant="contained" color="secondary">
                Secondary
            </Button>
            <Button variant="contained" disabled>
                Disabled
            </Button>
            <Button variant="contained" color="primary" href="#contained-buttons">
                Link
            </Button>
        </div>
        <div>
<Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
<Button variant="outlined">Default</Button>
<Button variant="outlined" color="primary">
  Primary
</Button>
<Button variant="outlined" color="secondary">
  Secondary
</Button>
<Button variant="outlined" disabled>
  Disabled
</Button>
<Button variant="outlined" color="primary" href="#outlined-buttons">
  Link
</Button>
        </div>

        <Link to="/">Go back to the homepage</Link>
    </Layout>
)

export default SecondPage
